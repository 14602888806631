
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CustomerAccounts from "@/dto/CustomerAccounts";
import CompanyDTO from "@/dto/company/CompanyDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class AdminCreationModal extends Vue{
    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    @Prop()
    private onSuccess!: () => void

    private request = new CreatePersonRequestDTO()

    closeModal(){
        this.$modal.hideAll();
    }

    handleSuccess() {
        this.onSuccess();
        this.stopLoading();
        this.closeModal();
    }

    errorHandling(err: any) {
        this.successful = false;
        let systemCode = err?.response?.data.systemCode;
        let param = err?.response?.data.param;
        this.$modal.hide("hide")
        switch (systemCode) {
          case 40025:
                this.message = this.buildTakenMessage(param as CustomerAccounts);
                break;
            default:
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
        }
        this.stopLoading();
    }

    handleCompaniesName(companies: Array<CompanyDTO>): string {
        let res = "";
        for (let i = 0; i < companies.length; i++) {
            res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
        }
        return res;
    }

    buildTakenMessage(customerAccounts: CustomerAccounts){
      const personFirstName = customerAccounts.person?.firstName;
      const personLastName = customerAccounts.person?.lastName;
      const employeeFirstName = customerAccounts.employee?.firstName;
      const employeeLastName = customerAccounts.employee?.lastName;
      const listCompanyNames = this.handleCompaniesName(customerAccounts.companies);
      const elems: Array<string> = [];
      if(personFirstName && personFirstName){
        elems.push(`individual ${personFirstName} ${personLastName}`);
      }
      if(employeeFirstName && employeeLastName){
        elems.push(`employee ${employeeFirstName} ${employeeLastName}`)
      }
      if(customerAccounts.companies?.length == 1){
        elems.push(`company ${listCompanyNames}`);
      } else if(customerAccounts.companies?.length > 1){
        elems.push(`companies ${listCompanyNames}`);
      }
      return `An account with the provided email is already associated with ${elems.join(", ")}`;
    }

    save() {
        this.$validator.validate().then(
            isValid => {
                if (isValid) {
                    this.startLoading()
                    this.message = ""
                    PersonService.registerAdmin(this.request).then(
                        () => this.handleSuccess(),
                        err => this.errorHandling(err)
                    )
                }
            }
        )
    }
}
